import * as React from "react"
import { Helmet } from "react-helmet"

import { Layout, Button, ButtonList } from "../components"

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet title="Page Not Found | GotTheTest" />
      <section className="wrapper-width wrapper-height text-center">
        <h1 className="heading-extra-large">Page not found</h1>
        <p className="margin-small-y">
          Sorry, this page no longer exists. The page may have been moved or
          deleted but don&apos;t worry, that&apos;s on us!
        </p>
        <ButtonList variant="centered">
          <Button>Back to homepage</Button>
        </ButtonList>
      </section>
    </Layout>
  )
}

export default NotFoundPage
